import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { t } from 'ttag';

const Heading = styled('h1')({ textAlign: 'center' });
const P = styled('p')({ textAlign: 'center' });

class NotFound extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
  };

  render() {
    return (
      <div>
        <Heading>{this.props.title}</Heading>
        <P>{t`Atsiprašome, tačiau puslapio šiuo adresu nėra`}</P>
      </div>
    );
  }
}

export default NotFound;
