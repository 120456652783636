import React from 'react';
import { t } from 'ttag';
import Layout from '../../components/Layout';
import NotFound from './NotFound';
import withLoading from '../../core/createLoadingAction';

function action() {
  const title = t`Puslapis nerastas`;
  return {
    chunks:    ['not-found'],
    title,
    component: (<Layout><NotFound title={title} /></Layout>),
    status:    404,
  };
}

export default withLoading(action);
